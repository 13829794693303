import React, {useState, createRef} from "react"

const inContainer = {
    textAlign: "left",
    paddingRight: "20%"
}

const outContainer = {
    textAlign: "right",
    paddingLeft: "20%"
}

const msgIn = {

    backgroundColor:'#fdfdfd',
    marginBottom:'1.5%',
    padding:'1.5%',
    borderRadius:'5px 5px 5px 0px',
    display:'inline-flex',
    fontSize:'1em',
}

const msgOut = {

    backgroundColor:'#29b7ca',
    marginBottom:'1.5%',
    padding:'1.5%',
    borderRadius:'5px 5px 0px 5px',
    display:'inline-flex',
    color:'white',
    fontSize:'1em',
}

const Messages = props => {

    const today = new Date();

    const d = new Date(props.created_at);

    const day = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][d.getDay()];

    return (
        <>
            <div style={props.sender === 'self' ? outContainer:inContainer}>
                <div style={props.sender === 'self' ? msgOut:msgIn} dangerouslySetInnerHTML={{__html:props.content}}></div>
            </div>
        </>
    )

}

export default Messages