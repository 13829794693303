import logo from './logo.svg';
import './App.css';

import React, { useState, useEffect, createRef } from 'react';
import { Flex, Box } from 'rebass';
import { Button, ButtonGroup, Drawer, DrawerSize, Position, AnchorButton, InputGroup, Card, Callout } from '@blueprintjs/core';
import axios from 'axios';
import Messages from './components/messages';

const notes_val = [
  '5th Nov 2024, 3:28pm. hey syg, im in bangkok. been travelling for so many weeks already, havent been in KL on a wednesday since forever. the only constant is my memory of you, in my first thought when i wake up, in my last dream before i wake up. i will see you, nothing will separate us forever',
  '13th mar 2024, 7:09am. syg i miss you so much. i miss you with each strand of time passing, with every beat of my heart and with all of my thoughts, awake and otherwise. i miss you. you are not here with me, but my heart is with you for an eternity.',
  '11th dec 2023, 11:43pm. why cant i have you syg? why?',
  '5th dec 2023, 11:58am. i need you, so badly, right now. i miss you, eternally. i will complete myself when i see you, for you have taken all of me when you leave.',
  '30th nov 2023, 10:46am. hi syg, i love you, will always love you. every single day you are my first thought when i wake up, and my last thought before i sleep. i miss you eternally. i cannot wait to see you. since you left me, i struggle to seek for meaning nor joy, and all i think about is seeing you one day. you touched me so deep, i dont know how to undo all this. I am now beyond broken, dont know how to piece together myself again, so i just want to wait and see you, and make myself whole again with you. ',
  '20th aug 2023, 2:48am. im trying so hard to put myself together every day. its hard. i need painkillers again. i miss you so much.',
  '8th may 2023, 11:22pm. im starting to cry every night, again. im not sure what to do anymore. i can just hope the pain will go away one day, if not i really want to end everything.',
  '30th apr 2023, 9:51pm. i think about u all the time. words cannot describe how painful every moment has been. you were my everything, you are still my everything. i cannot wait to see you syg. i love you, i still love you, with everything i have. i love you',
  '28th apr 2023, 1:23am. i am soo tired. every night i will break down and cry. my chest hurts soo much constantly, need to go for check up, dont dare to work out too much. im not sure when will this pain ends, if ever. this is so hard',
  '17th apr 2023, 11:58am. i miss you. i miss you so fucking much. im so numb, but sometimes when i feel something, it hurts beyond anything i felt before. i miss you syg',
  '9th apr 2023, 3:17pm. eva adelia sounds nice jgk kan?',
  '7th apr 2023, 10:25pm. its not fair at all. i cant even get closure. when you needed me, i was there for you all the way. i need you now. but i cant even say goodbye properly. not fair at all. im suffering so bad. all the time thinking of you.',
  '28th mar 2023, 10:26pm. im so tired. i miss you. i miss you 😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭😭 i miss you',
  '23rd mar 2023, 10:28pm. i love you syg. i love you so much. i miss you :(. i cannot wait to see you',
  '19th mar 2023, 10:50am. And if it\'s right, I don\'t care how long it takes, As long as I\'m with you, I\'ve got a smile on my face, Save your tears, it\'ll be okay, All I know is you\'re here with me. PS:- I still cant listen to tulus without tearing and breaking down, tulus will always be our songs. one day i might be able to listen to them again with a smile. maybe the day i see you again? i love you syg. ivana i love you so much',
  '18th mar 2023, 10:36pm. syg i dont know how i will go on, or how long i will be able to go on. some days i just wanna give up and end it all, i am in so much pain, all the time. i dont know why i need you so badly. i never needed anyone as bad as i need you right now :(',
  '11th mar 2023, 2:57am. i miss you syg. i miss you so much. i will always love you ivana',
  '12th feb 2023, 12:00am. happy 6 months syg. i love you so much. until the end. i love you ivana',
  '29th jan 2023, 12:20pm. help me to forget you please? its been nearly 6 weeks. i have not felt any happiness, any love, anything at all. do you know what it feels to have no hope whatsoever, to have everything drained out of your soul, to feel that death is the best thing to happen right now?',
  '28th jan 2023, 6:52pm. i dont think i can make it through this. i cannot control when i cry. i dont think i can my job. i xlarat dah. i just want all of this to end',
  '28th jan 2023, 4:23pm. why me? im suffering. why me? really ivana, why me?',
  '27th jan 2023, 8:11pm. i will be in jakarta next week, but im nearly two months late. really ivana, why me? do you see me suffer now?',
  '27th jan 2023, 12:46pm. why am i crying, when im not thinking of anything? what is the nature of loss, of mourning, and especially, of unfulfilled hopes and dreams? why is this something i am struggling with gravely, so much that i cannot think straight, i cannot feel any warmth nor can i feel any love. you know this is why i am afraid to dreams? i cannot bear my dreams being fulfilled. for the past 6-7 months, all i wanted was you. now that will never happen. you should have seen me before you leave. am i the one thats always selfish? am i that bad?',
  '24th jan 2023, 5:06pm ivana syg, i need u. i lost all feelings of love, all warmth of embrace, all joy of life. im lost syg. im lost. i need you',
  '24th jan 2023, 12:22am. i am broken. i am badly broken, to pieces, i dont know how to pick myself up. i will be forever hurt by the fact that i will never meet you. i try my best not to remind myself of that, but i fail everytime, and i hurt myself beyond anything i ever felt. the pain is real. i kept thinking of things i shouldnt, things that would spoil my memories of you, things that would just incite my pain. help me okay, however it is, help me. I still marah gila dkt KBR, because of him we never met, fuck that guy. fuck.',
  '23rd jan 2023, 7:15pm. syg i am really drowning. i think about ending my life all the time. please come to me, in my dreams, whatever, and tell me i will be alright :(',
  '21st jan 2023, 8:56pm. this has been one of the hardest day in my life. im shaking from grief, my chest hurt so bad, but i have to hide it all, and i have no one to turn to. im lost without you. im not sure i can make it till the end of the year. i gave you everything, i have nothing left to live for. see you soon okay.',
  '21st jan 2023, 12:00am. ivana syg, happy 28th birthday my little angel. be well okay, wherever you are. we will be together okay soon? i love you with everything i have.',
  '20th jan 2023, 4:20pm. i know u are gone. im in so much pain. visit me okay, tell me everything is going to be okay. i miss you. im so empty without you',
  '14th jan 2023, 5:00pm. why does this has to happen to me? kenapa tak jd dkt mamat petronas tu ke? dia menipu kot, still dia enjoy time with you. jadi dkt atmin ke, jd dkt MTD ke, sapa2 la yg u jmpa previously. i nk jmpa u even for coffee or drinks pon xleh then you go forever. how the fuck will i live? how the fuck do you think i can go on? how the fuck will i live with all the hopes and dreams yg never be fulfilled? i marah gila, i frust gila. i taktau i marah sapa. i just penat. penat gila feeling pain mcm ni.',
  '8th jan 2023, 7:07pm. if u are alive, can you hit the button? i am in deep shit. i am losing everything in my life. if you are indeed gone, maybe i will see u soon',
  '8th jan 2023, 4:18pm. i am drowning. i am not sure how to go on. i cannot feel anything, except overwhelming pain and sorrow. every minute is hard, every minute is a struggle from breaking down. everything reminds me of you. you need to visit me in my dreams, and tell me why i should go on. if not, i want to join you soon. i love you with everything i have',
  '8th jan 2023, 12:05am. to be really honest, i still cannot accept your death. if somehow you are alive, can you press the buttons and let me know. i am struggling. i am in soo much pain, constantly aching. i am questioning everthing. i love you. i need you. i miss the warmth of your voice. i miss you syg',
  '28th nov 2022, 8:34am. whenever i need to be truly happy, and feel some warmth inside of me, i will listen to your voice note. im hopelessly in love with you, soo deeply madly in love. i will always love you. until i die. these few days i have lost a big part of me by not being able to talk to you. praying you get better 🥺.',
  '22nd nov 2022, 12:01am. ivana syg, you are my happiness. you are my everything 🥺. i love you',
  '20th nov 2022, 5:24pm. syg, i miss you. i miss you. i miss you 🥺',
  '13th nov 2022, 10:16pm. mlm ni u have to visit me in my dreams tau syg 🧸❤️',
  '13th nov 2022, 5:38pm. syg i really really really am missing you 🥺. ishhh ngada nya i, but i miss you soo much',
  '13th nov 2022, 5:01pm. syg i miss you. i hope you are enjoying your day. ask that tiny dude to read something harder okay? haha. you are my soulmate, i long for you all the time, every step of the way. i love you syg.',
  '12th nov 2022, 9:58pm. syg i miss you so much. ishhh cepat la monday haha. i want to be with you. btw, before the day ends, happy monthiversary! 🧸❤️ ishhhh i cant believe its been 3 months, but i also cant believe we have only known each other for nearly 6 months je. i feel like i have known you all my life.',
  '8th nov 2022, 10:27pm. syg i saw something that made me emotional. only God can ever repay all that you have done for me. i realized i was totally exposed, and you sacrificed way too much to protect me 🥺. regardless of what you say, when the time comes, i will give you everything. i will always love you syg',
  '8th nov 2022, 2:57pm. syg you will always have me. whatever it is, whatever it will be. im always with you okay?',
  '1st nov 2022, 7:34pm. syg im not sure what is up with me today, but im struggling. a good struggle tho, i miss you soo much, like ridiculously much. i dont know why. i miss you',
  '1st nov 2022, 7:10pm. i always je miss you. always. i want you. i love you.',
  '31st oct 2022, 6:47pm. everytime I think about you (quite often these days, if not constantly), I feel some sort of warmth and comfort. a feeling that you might have coming home after a long time away. maybe we have been looking for each other for the longest time. now that i found you, no matter what, my heart will never let you go, because you are already a part of me.',
  '31st oct 2022, 1:49am. i am still thinking about you. each morning when i wake up, and each night before i sleep, i think about how lucky i am to have you in my life. i will always love you',
  '30th oct 2022, 6:41pm. syg i really miss you 🥺. i want you right here, right now',
  '30th oct 2022, 10:36pm. all that matters to me is you. i love you',
  '29th oct 2022, 10:33pm. syg if you cannot sleep, feeling mellow, come here. put your head on my chest, I will wrap my hands around you, kiss your forehead and your hair, and make sure you feel safe and calm. I will tell you facts about space, and we can together speculate whether or not we are alone in the universe. I think we are not. What do you think? I make you tea, and hug you until you fall asleep, nah, I will hug you until morning, make sure you have a good sleep. I love you',
  '28th oct 2022, 11:15pm. syg i miss you so much 🥺. goodnight tau',
  '28th oct 2022, 10:04pm. i forgot to tell you that tomorrow i have an office thing to attend until evening-ish. can text :D. love you',
  '28th oct 2022, 10:31am. syg u need to take care of yourself. you have done way too much for me. let me know if anything, i mean, ANYTHING, at all. okay?',
  '28th oct 2022, 6:47am. good morning gf. nah you are way more than that. you are my everything. i love you',
  '27th oct 2022, 6:51am. good morning syg. i love you so much',
  '26th oct 2022, 3:03pm. i cant explain it, but i miss u dearly. i miss u terribly. i really want to hug you :(',
  '26th oct 2022, 7:40am. syg u okay kan? tell me if anything is not okay, tau?',
  '24th oct 2022, 7:23pm. a random thought. dont you think this website is a cool story to tell our kids? :D',
  '24th oct 2022, 7:54am. syg i really miss u. i woke up and miss u already. :(',
  '23rd oct 2022, 9:37am. ivana syg, i am really really missing you. love you so much',
  '23rd oct 2022, 8:00am. every single time i wake up, i will think of you first. you have a great day today okay syg? i love you',
  '22nd oct 2022, 10:10pm. i really do miss you syg. i will always love you. i will wait for you to get better. then we will do all the things we want to do together okay?',
  '22nd oct 2022, 2:53pm. one day i will give you everything. i want to and i will. and i dont want to ever lose you.',
  '22nd oct 2022, 8:57am. syg u have great day okay? i love you',
  '21st oct 2022, 5:01pm. syg i love you. i miss you.',
  '21st oct 2022, 10:31am. from the first time i called u syg, i meant it, and i didn\'t take it lightly. you mean the world to me. i love you with everything i have',
  '21st oct 2022, 6:00am. syg u dont leave me tau. i dont think i\'ll survive.',
  '21st oct 2022, 5:40am. my first thought when i woke up earlier was that i cannot imagine a life ahead without you.',
  '20th oct 2022, 5:53am. every morning i wake up i will think of you, and i will miss you already.',
  '19th oct 2022, 6:13pm. syg i am happy and productive this week. i will always be there with you, i am happy to be there for you.',
  '18th oct 2022, 12:01am. goodnight syg. i hope you are doing well. always let me know if something, anything at all, is wrong, okay?',
  '16th oct 2022, 10:46am. i am missing you. i miss you constantly. i think about you all the time. i always hope you are doing well, and will do well. i love you',
  '15th oct 2022, 11:56pm. i love you. all the good and bad things about you. every single thing about you. and these few days i have really been missing you a lot',
  '15th oct 2022, 7:36pm. ivana you made me feel alive. you made me feel i want to live fully, with you. you gave me what i lost. you give me everything',
  '14th oct 2022, 9:33am. i love you, unconditionally, with everything i have',
  '12th oct 2022, 7:05am. i miss you. i slept thinking about hugging you. i woke up thinking about you still in my arms. i really like the smell of your hair. i love you.',
  '11th oct 2022, 2:27pm. you know how exactly you make me feel? you make me feel warm, you make me feel full, you make me feel full of love. you make me dream, you make me hope and you make me think of the future. you fill me up with all the vitality i never found in life before, you pour me all the feelings one one can only imagine exists, and you make be the best version of myself that i can possibly be. i will never get enough of you. of your laughter, of your replies, of your character and personality. most importantly, i will never get enough of your love. it is the most precious thing someone can ever wish for. i constantly miss you. i sincerely love you. and, i desperately need you. i am desperate for you.'
]

const options_val = [
  'hello',
  'yo!',
  'i love you',
  'love',
  'i miss you',
  'emir a',
  'ivana',
  'awesome',
  'u are so cute',
  'goodnight',
  'good morning',
  'yayang',
  'to be',
  'sayang',
  'my ivana',
  '🧸',
  '❤️',
  '🥺',
  '👋',
  'sorry',
  'mengada',
  'much',
  'VJJ',
  'wuuuuuuuuuu',
  'tau',
  'tau tau tau',
  'ishhhhh',
  'Thanks',
  'HUGS',
  'KISSES',
  'CUDDLES',
  'evan comel',
  'eva comel',
  'ivana comel',
  'for',
  'i',
  'you',
  'me',
  'are',
  'am',
  'okay',
  'not',
  'too',
  'i am okay',
  'i am not okay',
  'so much',
  'too much',
  'not enough',
  'never enough',
  'my',
  'everything',
  'we',
  'meant',
  'forever'
]

const myRef = createRef()
const bottomRef = createRef()

function App() {

  const [time, setTime] = useState(new Date().get);
  const [hour, setHour] = useState(new Date().getHours());
  const [minute, setMinute] = useState(new Date().getMinutes());
  const [second, setSecond] = useState(new Date().getSeconds());
  const [tick, setTick] = useState(true);
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [notes, setNotes] = useState(notes_val);
  const [options, setOptions] = useState(options_val);
  const [validated, setValidated] = useState(false);
  const [user, setUser] = useState('');
  const [chats, setChats] = useState([]);
  const [code, setCode] = useState('');
  const [text, setText] = useState('');

  const [chatOpen, setChatOpen] = useState(false)


  const [authenticating, setAuthenticating] = useState(false);
  const [loading, setLoading] = useState(false);

  const [isSpecialOpen, setIsSpecialOpen] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {

      const d = new Date();

      setHour(d.getHours());
      setMinute(d.getMinutes());
      setSecond(d.getSeconds());
      setTick(d.getSeconds()%2 === 0 ? true : false);

    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect( () => {
    const chatInterval = setInterval(() => {

      if(validated) {
        (async () => {
          await getChat();
          try {
            window.scrollTo(0, bottomRef.current.scrollIntoView())
          } catch(e) {
    
          }
        }) ()
      }


    }, 5000);
    return () => {
      clearInterval(chatInterval);
    };
  }, [validated])

  useEffect(() => {
    try {
      // window.scrollTo(0, bottomRef.current.scrollIntoView())
    } catch(e) {

    }
  }, [chats])

  function exit() {
    setValidated(false);
    setChats([]);
    setUser(null);
  }

  function validate() {

    setAuthenticating(true)

    axios.get('https://some-portal-backend.onrender.com/chat/auth/' + code).then(res => {

      const role = res?.data?.data;

      setUser(role);
      setValidated(true);

      (async () => {
        await getChat();
      }) ()

    }).catch(() => {
      alert('invalid code!')
    }).finally(() => {
      setAuthenticating(false)
    })

  }

  function sendChat(content) {

    const payload = {
      sender : user,
      content,
      created_at : new Date()
    }

    axios.post('https://some-portal-backend.onrender.com/chat', payload).then(() => {
      setChats([...chats, payload]);
      setText('')
    }).finally(() => {
      try {
        window.scrollTo(0, bottomRef.current.scrollIntoView())
      } catch(e) {}
    })
  }

  async function getChat() {
    axios.get('https://some-portal-backend.onrender.com/chat').then(res => {
      const resp = res?.data?.data;

      if(resp !== chats) setChats(resp);

    }).finally(() => {

    })
  }

  return (
    <div>
      <header >

        <Drawer isOpen={isSpecialOpen} size={"100%"} position={ Position.BOTTOM } style={{ padding : '20px', height : '100vh', overflowY : 'scroll' }}>

          <Flex>

          <Box width={0.85}>
          </Box>
          <Box width={0.15} style={{ textAlign : 'right' }}>
            <AnchorButton minimal icon={'cross'} onClick={() => { setIsSpecialOpen(false) }} ></AnchorButton>
          </Box>
          </Flex>

          <h1>

          </h1>

          <h1>
            There is no backing off right now without severe cost to my soul, and perhaps to yours as well. I am already way too deep with you.
          </h1>
          
          <h1>
            From the moment we got in touch, fate has perhaps decided that I will love you forever. Whether or not we end up together, you will always be my Ivana.
          </h1>

          <h1>
            Whatever feelings I have for you was forged in my heart, onto my mind and all around my soul. Feeling you in the flesh is just an upshot of all that. My desire for you transcends any need for physical pleasure. Loving you with my soul suffices, but having you in my arms will complete me.
          </h1>

          <h1>
            You have to know that you are a wonderful human being. Your heart is pure. Even when you were struggling, you thought of my wellbeing. It is hard to be any purer than that. I know I may not be able to do that. What you did to protect my feelings (and I know this) costed you a lot. I owe you everything. I mean this, I owe you everything. I owe you my life, I owe you the will to carry on and push forward, and I owe it to you to do things you always know I can do, eventhough I myself have doubts about it.
          </h1>

          <h1>
            Offering you everything is the least I can do. Right now I won't be able to give you much. However (for whatever it is worth), I will always love you. You will always have me. I constantly think of you, and I love you with every beat of my heart, with every drop of my blood, with everything I have.
          </h1>

          <h1>
           Ivana, without any conditions, I love you.
          </h1>
        </Drawer>

        <Drawer isOpen={chatOpen} size={"100%"} position={ Position.BOTTOM } className={'diff-font'}>

          <Flex flexWrap='wrap'>
            <Box width={[1, 1, 1, 0.333]} />
            <Box width={[1, 1, 1, 0.333]} style={{ background : 'lightblue' }}>

            <div style={{ height : '8vh',background : 'azure' }}>
              <Flex>

                  <Box width={0.85} style={{padding : '12px'}}>
                    <h3 style={{marginTop : '0px'}}>ia + ea</h3>
                  </Box>
                  <Box width={0.15} style={{ textAlign : 'right' }}>
                    <AnchorButton minimal large  icon={'cross'} onClick={() => { exit(); setChatOpen(false)}} ></AnchorButton>
                  </Box>
                </Flex>
            </div>

            {validated ? (
              <>
                <div style={{ height : '62vh', padding : '20px', overflowY : 'scroll' }} ref={myRef}>

                {chats.map(chat => {
                      const {sender, content, created_at} = chat;
                      return(
                          <Messages sender={sender === user ? 'self' : 'other'} content={content} created_at={created_at} />
                      )

                })}

                <div style={{height : '10vh'}}></div>

                <div ref={bottomRef}/>
                </div>
                <div style={{ height : '20vh', overflowY : 'scroll' }}>

                {/* <InputGroup 
                  large
                  placeholder='type ur message' 
                  value={text}
                  onChange={e => {
                    setText(e.target.value)
                  }}
                  rightElement={
                    <Button 
                      loading={loading} 
                      text={'Send'} 
                      onClick={() => { sendChat(text) }} 
                    />} 
                />  */}

                <Flex flexWrap='wrap'>
                {options.map(option => {
                    return(
                      <Box>
                        <Button onClick={() => sendChat(option)} large text={option} loading={loading}></Button>
                      </Box>
                    )
                  })}
                </Flex >
                <br />
                </div>
              </>
            ) : (
              <div style={{ height : '92vh', overflowY : 'scroll' }}>
                <h6>Passcode is our monthiversary DDMM</h6>
                <Flex flexWrap='wrap'>

                  <Box width={0.20} style={{ textAlign : 'center' }}>
                    <h1>
                      #
                    </h1>
                    
                  </Box>
                  {code.split('').map(s => {
                    return(
                      <Box width={0.20} style={{ textAlign : 'center' }}>
                        <h1>
                          {s}
                        </h1>
                      </Box>
                    )
                  })}
                </Flex>

                <Flex flexWrap='wrap'>
                  {['123456789*0#'.split('').map(s => {
                    return(
                      <Box width={.333}>
                        <Button minimal intent='primary' onClick={() => code.length <= 3 ? setCode(code + s) : null} fill text={<h2>{s}</h2>} />
                      </Box>
                    )
                  })]}
                </Flex>
                <br />
                <Flex>
                  <Box width={0.666}>
                    <Button 
                    large
                    intent='primary'
                    fill
                        loading={authenticating} 
                        text={'Enter'} 
                        onClick={() => { validate() }} 
                      />
                  </Box>
                  <Box width={0.333}>
                    <Button large intent='danger' onClick={() => setCode(code.slice(0, -1)) } fill icon={'key-backspace'} />
                  </Box>

                </Flex>
                <div style={{height : '10vh'}}></div>
                {/* <InputGroup 
                  large
                  placeholder='Enter the last 4 digits of my phone number' 
                  value={code}
                  onChange={e => {
                    if(e.target.value.length <= 4) {
                      setCode(e.target.value)
                    }
                  }}
                  rightElement={
                    <Button 
                      loading={authenticating} 
                      text={'Enter'} 
                      onClick={() => { validate() }} 
                    />} 
                /> */}
              </div>
            )}

            </Box>
          </Flex>
        </Drawer>

        <Flex flexWrap='wrap'>

          <Box width={[ 1, 1,1,0.333 ]} style={{ padding : '20px', background : 'lavenderblush' }}>

            <Card elevation={2}>
              <h6>In the loving memory of</h6>
              <h1 style={{marginBottom : '0px', marginTop : '0px'}}><b>Ivana Aqila</b></h1>
              <h6>I love you. I will always love you.</h6>
            </Card>

            <br />

            <Card elevation={2}>
              <Flex>
                <Box width={0.65}>
                  <h1 style={{ fontSize : '96px',lineHeight: '96px',  marginBottom : '0px', marginTop : '0px' }}>
                    {`${hour}:${minute}:${second}`}
                  </h1>
                  <h5 style={{marginTop : '0px'}}>All the seconds when I miss you.</h5>
                </Box>
                <Box width={0.35} style={{ textAlign : 'left'}}>
                  <h1 >
                    {tick ? '🧸❤️' : '🧸'}
                  </h1>
                </Box>
              </Flex>
              <h1 style={{marginTop : '0px'}}>{date}</h1>
            </Card>

            <br />

            <Card elevation={2}>
              <ButtonGroup fill>
                <Button minimal large icon={'chat'} text={'Chat'} intent={'primary'}  onClick={() => setChatOpen(!chatOpen)} ></Button>
                <Button minimal large icon={'manual'} text={'For Ivana'} intent={'success'} onClick={() => setIsSpecialOpen(true)} ></Button>
                <Button minimal large icon={'heart'} intent={'danger'} onClick={() => alert('Ivana I love you so much ❤️')} ></Button>
              </ButtonGroup>
            </Card>

            <br />

            <Card elevation={2} >
              <h3 className={'diff-font'}>Feeds</h3>
              <div style={{ height : '50vh', overflowY : 'scroll' }}>
                {notes.map(note => {
                  return(
                    <h4 className={'diff-font'}>{note}</h4>
                  )
                })}
              </div>
            </Card>
          </Box>

          <Box width={[ 1, 1,1,0.333 ]} style={{ padding : '20px', background : 'azure' }}>
          <h1>ia + ea + eva a + evan a wonderland</h1>

          <h1>
              in my dreams, you are mine, i am yours, exclusively.
          </h1>

          <h1>
              in that dream we have a son, playful and pure like you, and a daughter, nerdy like me. but both are awesome like their mum, and irrational and stupid, like their dad. the three of us love you beyond anything else, beyond any words.
          </h1>

          <h1>
              we cuddle every night, talking about the latest Prada, the progress in physics and maybe the future of us (such as which country to migrate to, what car to waste money on, and which dog to get)
          </h1>

          <br />
          <br />
                
          <h1>ia + ea loves each other so much, of course two more people in this world can be tolerated, right?</h1>
          
          <br />

          <h1>eva aqila</h1>

          <h1>
            eva, as mentioned, is a nerd. she has a particular interest in insects, such that her room is filled with dead butterflies. but like her mum, she likes Belle. so much so that every birthday is a beauty and the beast themed party. and every single one of that party, her dad will need to cosplay as the Beast :(. 
            </h1>
            
            <h1>evan atif</h1>
            
            <h1>
              like her mum, evan is a bit more loose than eva, and perhaps also his dad. he is the class clown, chief entertainer of any crowd. he is special in every way, basically the male version of his mum. he likes cars, of course. both his parents like cars too. 
            </h1>

          </Box>

          <Box width={[ 1, 1,1,0.333 ]} style={{ padding : '20px', background : 'white' }}>

              <Callout intent={'primary'} title={'A wise man once said...'}>
                <h1>
                  "Life is full of sweet mistakes
                </h1>

                <h1>
                  And love's an honest one to make"
                </h1>
              </Callout>

          <h1>
              you will always be my baby, always my ivana. I loved you, i still love you, i will forever love you.
          </h1>

          <h1>
              you are my greatest blessing. god loaned you to me, to make me realize i can be a far better person than i was. i have much to do. and i have so many love to give to everyone around me. i wish i can have you by my side forever.
          </h1>

          <h1>
              i will carry you in my heart until the day i die.
          </h1>

          <h1>
              i will include you in my prayers, if that means anything. i will ask for your health and happiness, even at the expense of mine.
          </h1>

          <h1>
              and i will always be there for you if you need me. you can always call me, anytime you need me, anywhere in the world.
          </h1>

            <h1>
              it is not goodbye, it will never be goodbye, because you will always live in me. parts of my heart are made by you.
          </h1>


          <h1>
              my dear ivana. please take care sayang. 
          </h1>

            <h1>
              i love you, with everything i have
          </h1>
          
          <br />

          </Box>

          
        </Flex>

      </header>
    </div>
  );
}

export default App;
